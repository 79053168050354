export default [
  {
    header: "Home",
  },
  {
    title: "Dashboard",
    route: "home",
    icon: "HomeIcon",
  },
  {
    title: "Customers",
    route: "customers",
    icon: "InfoIcon",
  },
  {
    title: "My Cases",
    route: "my-cases",
    icon: "FileTextIcon",
  },
  {
    title: "Coupon Codes",
    route: "coupon-codes",
    icon: "InfoIcon",
  },
  {
    title: "News",
    route: "news",
    icon: "InfoIcon",
  },
  {
    title: "Invoices",
    route: "invoices",
    icon: "FileTextIcon",
  },
  {
    title: "Global Configuration",
    route: "global-configuration",
    icon: "SettingsIcon",
  },
  {
    title: "Credits Packages",
    route: "packages",
    icon: "ShoppingCartIcon",
  },
  {
    title: "Ticket System",
    route: "tickets",
    icon: "CheckSquareIcon",
  },
  /*
  {
    title: 'Packages',
    route: 'packages',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Invoices',
    route: 'invoices',
    icon: 'FileTextIcon',
  },*/ {
    header: "Storage",
  },
  {
    title: "Case List",
    route: "case-list",
    icon: "ListIcon",
  },
  /*{
    labels: ["cloud-storage"],
    title: 'Cloud Storage',
    route: 'cloud-storage',
    icon: 'HardDriveIcon',
  },*/
  // {
  //   header: "Extensions",
  // },
  // {
  //   title: "2D to 3D",
  //   route: "2d-to-3d",
  //   icon: "CpuIcon",
  //   labels: ["2d3d-extension"],
  // },
];
